import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useOktaAuth } from '@okta/okta-react';
import {SimpleExport,ExportButtonsExportCancel, ThemeProvider} from"tgsccl-export";
import {auth} from "tgsccl-core";
import { ExportData, UserInfoData} from "./IselectInput-interface";
import { removeInvalueUwiFilter } from "./utils/search-input-util";
const { ClaimsContextProvider: ClaimsContext} = auth;

function DynamicModal(props: Readonly<ExportData>) {
    const [searchCriteria,setSearchCriteria]=useState({});
    const { oktaAuth, authState } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<UserInfoData | null>(null);

    useEffect(() => {
        if (!authState?.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                  if(info.name && info.email)
                    {
                        setUserInfo({ name: info.name, email: info.email});
                    }
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]);

function getSearchCriteria(){
    let statesToQuery = [];
    let statesFromSelectedCounties:string[] = [];
    let countiesToQUery = [];
    let countyVal = [];
    let reverseCountyVal = [];
    let stateFromParent = [];
    for (let county of props.dataSelectedCounty) {
        statesFromSelectedCounties.push(county.State);
        countiesToQUery.push(county.label);
    }
    statesFromSelectedCounties = [... new Set(statesFromSelectedCounties)];
    for (let stateQuery of props.dataSelectedOption) {
        statesToQuery.push(stateQuery.label);
    }
    statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));


    for (let state of props.dataSelectedOption) {
        stateFromParent.push(state.label);
    }
    for (let d = 0; d < props.dataSelectedCounty.length; d++) {
        countyVal.push(props.dataSelectedCounty[d].label);
        let str = countyVal[d]
        reverseCountyVal.push(str.split(' - ').reverse().join(' - '));
    }

    if (stateFromParent.length > 0 && reverseCountyVal.length == 0 && props.dataSelectedTab == 'HeaderSearch') {
        setSearchCriteria({
            "States": stateFromParent
           });
    } else if (((reverseCountyVal.length > 0 && stateFromParent.length == 0) || (countiesToQUery.length > 0 && statesToQuery.length == 0)) && props.dataSelectedTab == 'HeaderSearch') {
        setSearchCriteria({
            "Counties": reverseCountyVal
           });
    } else if (reverseCountyVal.length > 0 && statesToQuery.length > 0 && props.dataSelectedTab == 'HeaderSearch') {
        setSearchCriteria({
             "States": statesToQuery,
             "Counties": reverseCountyVal
            });
    } else {
        setSearchCriteria({
            "States": [],
            "Counties": []
          });
    }

    if (props.dataUWiList.length > 0 && props.dataSelectedTab == 'uwlList') {
            setSearchCriteria({
                "States": [],
                "Counties": [],
                "uwiList": props.dataUWiList.filter(removeInvalueUwiFilter)
              })
        }
}

   
      useEffect(() => {
		getSearchCriteria();
	},[props.dataSelectedOption, props.dataSelectedCounty, props.dataUWiList, props.dataSelectedTab]);

    return (
        <>
         {userInfo ? <ThemeProvider theme={'default'}>
            <Modal show={props.show} size="lg" centered >
                <Modal.Body style={{padding:'0px'}}>

                    <ClaimsContext defaultProviderToken={oktaAuth.getAccessToken()}>
                      < SimpleExport title={'Export Options'} initialName={""} userInfoData={userInfo}
                              initialDescription={""} productTypes={["WellProductionData"]} sourceSystemValue={"WellDataDelivery"}
                              searchCriteria={searchCriteria}
                              showScheduleSectionFlag={true}
                              Buttons={ExportButtons}
                              onCancel={props.close} 
                      />
                </ClaimsContext>

                </Modal.Body>
            </Modal>
         </ThemeProvider>:<></>}
        </>
    );
}
const ExportButtons = (options: any) =>  <ExportButtonsExportCancel {...options} />
export default DynamicModal;
