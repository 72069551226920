import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppWithRouterAccess from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'tgsccl-export/tgs.components.core-export.default.css';
ReactDOM.render(
  <React.StrictMode>
    <AppWithRouterAccess/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
