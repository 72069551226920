import React from "react";
import { CountyOption, DataCount } from "./IselectInput-interface";
import { AutoSelectOption } from "tgsccl-core-mui/components/auto-complete-select/auto-complete-select";
import { getWellCount } from "./utils/search-input-util";

export const Count = (props:{data:DataCount,data2:AutoSelectOption[],data3:CountyOption[]}) => {
    return (
        <div>
            <h6 className="m-3" style={{ textAlign: 'left', display: 'block' }}>Well & Production</h6>
            <div className="container-fluid px-6">
                <div className="row gx-4">
                    <div className="col">
                        <div className="p-3 border bg-light shadow">
                                <div className="text-center">
                                    <div className="text-center">                                        
                                         <h5 className="card-title">
                                                {getWellCount(props.data2, props.data3, props.data.getWellCount, props.data.stateWellCount, props.data.countyWellCount, props.data.wellcount)}
                                        </h5>
                                        <p>Well Count</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="p-3 border bg-light shadow">
                                <div className="text-center">
                                    <div className="text-center">
                                         <h5 className="card-title">
                                                {getWellCount(props.data2, props.data3, props.data.getProductionCount, props.data.stateProductionCount, props.data.countyProductionCount, props.data.wellProductionCount)}
                                        </h5>
                                        <p>Well Production</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="p-3 border bg-light shadow">
                                <div className="text-center">
                                    <div className="text-center">
                                        <h5 className="card-title">
                                                {getWellCount(props.data2, props.data3, props.data.getwellActiveProducingCount, props.data.statewellActiveProducingCount, props.data.countywellActiveProducingCount, props.data.wellActiveProducingCount)}
                                        </h5>
                                        <p>Actively Producing</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <h6 className="m-3" style={{ textAlign: 'left', display: 'block' }}>Profile</h6>
            <div className="container-fluid px-6">
                <div className="px-6">
                    <div className="row gx-4">
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                    <div className="text-center">
                                         <h5 className="card-title">
                                                {getWellCount(props.data2, props.data3, props.data.getUnknownCount, props.data.stateUnknownCount, props.data.countyUnknownCount, props.data.unkowncount)}
                                        </h5>
                                        <p>Unknown</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                    <div className="text-center">
                                         <h5 className="card-title">
                                            {getWellCount(props.data2, props.data3, props.data.getslantSideTrackCount, props.data.stateslantSideTrackCount, props.data.countyslantSideTrackCount, props.data.slantSideTrackCount)}
                                        </h5>
                                        <p>Sidetrack</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                    <div className="text-center">
                                         <h5 className="card-title">
                                            {getWellCount(props.data2, props.data3, props.data.getslantDirectionalCount, props.data.stateslantDirectionalCount, props.data.countyslantDirectionalCount, props.data.slantDirectionalCount)}
                                        </h5>
                                        <p>Directional</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                    <div className="text-center">
                                        <h5 className="card-title">
                                            {getWellCount(props.data2, props.data3, props.data.getslantVerticalCount, props.data.stateslantVerticalCount, props.data.countyslantVerticalCount, props.data.slantVerticalCount)}
                                        </h5>
                                        <p>Vertical</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                    <div className="text-center">
                                         <h5 className="card-title">
                                            {getWellCount(props.data2, props.data3, props.data.getslantHorizontalCount, props.data.stateslantHorizontalCount, props.data.countyslantHorizontalCount, props.data.slantHorizontalCount)}
                                        </h5>
                                        <p>Horizontal</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Count