import { useOktaAuth } from '@okta/okta-react';
import {configuration} from "tgsccl-core";
import { postAPI } from './Api';
import { TgscclSettings } from '../IselectInput-interface';

const {useApplicationSettings} = configuration;   
const usePostApi=() => {
    const { tgscclSettings: appSettings }= useApplicationSettings<TgscclSettings>();
    const { oktaAuth }:any = useOktaAuth();
return (data: any)=> postAPI(data, oktaAuth.getAccessToken(), appSettings.REACT_APP_SEARCH_APIURL, appSettings.EXPORT_API_KEY);
}

export default usePostApi;